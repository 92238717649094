import React from "react"
import Container from "../components/Layout/Container"
import Collapsible from "../components/Elements/Collapsible.js"
import SEO from "../components/Layout/SEO"

const PrivacyPolicy = () => {
  return (
    <Container isCentered>
      <SEO title="Privacy Policy" />

      <hr />

      <div className="content is-small">
        <div className="message mb-1">
          <div className="message-body">
            You should carefully read the entire following privacy statement
            before creating your account and/or availing our online products.
            This agreement contains important terms that affect your legal
            rights. By registering to our site, you hereby agree to the terms of
            this privacy statement policy.
          </div>
        </div>

        <div className="is-flex-row-desktop is-hidden-mobile is-flex my-2">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/medgrocer-28d8f.appspot.com/o/medgrocer.com%2Fweb-content%2FPrivacyRegistrationForm.svg?alt=media&token=8ccdb32c-207c-4af6-a6eb-c63397d7abad"
            alt="Privacy Registration Form"
            className="side-image is-hidden-mobile px-5 "
            width="350px"
          />
          <div className="is-size-6">
            MedGrocer respects the privacy of its online visitors and users of
            its products and services and complies with Republic Act No. 10173
            also known as the Data Privacy Act of 2012, its implementing rules
            and regulations, and other issuances of the National Privacy
            Commission. MedGrocer recognizes the importance of protecting
            information collected from users and has adopted this privacy policy
            to inform users about how MedGrocer collects, uses, stores,
            transfers/discloses, and disposes information derived from their use
            of MedGrocer products, services, and online sites. <br />
            <br /> This privacy statement is current as of its last revision
            date. However, please note that this privacy statement may be
            amended from time to time to reflect changes and additions to the
            privacy policy. Please check back for the most current version
            before relying on any of the provisions in this privacy statement.
          </div>
        </div>

        <div className="is-hidden-tablet is-flex-row-mobile is-flex is-flex-direction-column is-align-items-center is-justify-content-center my-2">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/medgrocer-28d8f.appspot.com/o/medgrocer.com%2Fweb-content%2FPrivacyRegistrationForm.svg?alt=media&token=8ccdb32c-207c-4af6-a6eb-c63397d7abad"
            alt="Privacy Registration Form"
            className="is-hidden-desktop mb-2"
            width="150px"
          />
          <div className="is-size-6 ">
            MedGrocer respects the privacy of its online visitors and users of
            its products and services and complies with Republic Act No. 10173
            also known as the Data Privacy Act of 2012, its implementing rules
            and regulations, and other issuances of the National Privacy
            Commission. MedGrocer recognizes the importance of protecting
            information collected from users and has adopted this privacy policy
            to inform users about how MedGrocer collects, uses, stores,
            transfers/discloses, and disposes information derived from their use
            of MedGrocer products, services, and online sites. <br />
            <br /> This privacy statement is current as of its last revision
            date. However, please note that this privacy statement may be
            amended from time to time to reflect changes and additions to the
            privacy policy. Please check back for the most current version
            before relying on any of the provisions in this privacy statement.
          </div>
        </div>

        <Collapsible title="Personally Identifiable Information">
          "Personally identifiable information" is information that can be used
          to uniquely identify a user such as name, password, age, civil status,
          gender, mailing address, email address, telephone number, mobile
          number, and other personal details. You may be asked to provide
          personally identifiable information during the sign in process to
          allow you to use the Product Site in our web site and for us to
          respond to your queries and requests. We may also use the provided
          personal information to inform you of special product offers, to
          process your orders, to provide you with our products and services or
          to conduct surveys. While MedGrocer collects personally identifiable
          information on a voluntary basis, for certain products and services,
          MedGrocer's collection of personally identifiable information may be a
          requirement for access to our products or services or to process a
          user’s order.
        </Collapsible>

        <Collapsible title="Collection of Information">
          By using MedGrocer's online sites, products, and services, users agree
          that MedGrocer collects and processes the personally identifiable
          information (as defined above) they have provided by creating a
          MedGrocer account, during any exchange with MedGrocer in connection
          with the use of their MedGrocer account. MedGrocer will not share any
          personally identifiable information with other parties except as
          described in this policy. MedGrocer also processes anonymous data,
          aggregated or not, in order to analyze and produce statistics related
          to the product usage patterns, and demographics of users. Such
          anonymous data does not allow the identification of the users to which
          it relates. MedGrocer may share anonymous data, aggregated or not,
          with third parties.
        </Collapsible>

        <Collapsible title="Use of Personally Identifiable Information">
          Personally identifiable information is used internally by MedGrocer to
          deliver, develop, and improve products, content, and services, to
          which users have subscribed, and to answer users’ requests. In
          addition, MedGrocer may allow third parties performing services under
          contract with MedGrocer, such as order or payment processors or
          merchandise warehouse and fulfillment services, located in and outside
          the Philippines, to access and use personally identifiable
          information, but only to the extent necessary to provide those
          services. MedGrocer may use personally identifiable information
          provided by users to send them information about MedGrocer, including
          news about product updates, events, and other promotional materials,
          but only if the users agree to receive such communications. MedGrocer
          will not share any personally identifiable information with third
          parties for marketing purposes without your consent. When you create a
          MedGrocer account, MedGrocer collects a user's email address and
          username, and at the user's option, first and last name. MedGrocer has
          no obligation to keep personally identifiable information that a user
          makes available to other users through a platform other than MedGrocer
          software, such as in chat or other public functions. External websites
          and companies with links to and from MedGrocer's online sites and
          products may collect personally identifiable information about users
          when users visit their websites. MedGrocer 's privacy policy does not
          extend to external websites and companies or third party publishers
          with links to or from MedGrocer’s products and online websites or who
          collect personally identifiable information through their on-line
          sites. Please refer directly to these companies and websites regarding
          their privacy policies. MedGrocer may release personally identifiable
          information to comply with court orders or laws that require us to
          disclose such information. In the event of a reorganization, sale or
          merger, we may transfer personally identifiable information to the
          relevant third party with the user’s consent if required by law.
        </Collapsible>

        <Collapsible title="Children Under the Age of 18">
          MedGrocer will not knowingly collect personally identifiable
          information from any person under the age of 18. MedGrocer encourages
          parents to instruct their children to never give out personal
          information when online. Parents who are concerned about the transfer
          of personal information from their children may contact us.
        </Collapsible>

        <Collapsible title="Cookies and Other Information on a User's Machine">
          MedGrocer’s products, services and sites may employ cookies and other
          technologies such as pixel tags. Cookies are bits of electronic
          information that can be transferred to a user's hard drive to
          customize a person's use of a product or online site, keep records of
          a user's access to an online site or product, or store information
          needed by the user on a regular basis. Use of cookies is typically
          associated with websites. For example, when you sign in to MedGrocer’s
          website, it stores your user ID and a combination of your IP address
          and time of login as a cookie on your hard drive. This cookie to allow
          you to move from page to page without having to sign in again on each
          page. Similarly, if you enter information during your session, such as
          age verification data, this will be stored as a cookie and you will
          not have to re-enter such information during that session. We may also
          use third party web site analytic tools that employ cookies to collect
          certain non-personally identifiable information concerning use of our
          websites, such as referring web site addresses, page views and browser
          types.
        </Collapsible>

        <Collapsible title="Choice/Opt-out">
          MedGrocer gives users the option to receive promotional email
          communications from MedGrocer. Users may opt out of these
          communications. An unsubscribe link for discontinuing receipt of
          promotional email communications from MedGrocer will be provided in
          the emails sent.
        </Collapsible>

        <Collapsible title="Storage and Security of Personally Identifiable Information">
          Personally identifiable information provided to MedGrocer will be
          collected, processed and stored by MedGrocer in the cloud. MedGrocer
          has taken reasonable steps to protect the information users share with
          us, including, but not limited to, setup of processes, equipment and
          software to avoid unauthorized access or disclosure of this
          information.
        </Collapsible>

        <Collapsible title="Corrections, Updates, and Removal of Personally Identifiable Information">
          If you are concerned about the accuracy of personally identifiable
          information maintained by MedGrocer or wish to review, access, amend
          or correct it, or would like your personally identifiable information
          removed from MedGrocer's records or otherwise deactivated, please
          contact us at{" "}
          <a
            href="mailto:dpo@medgrocer.com"
            className="has-text-primary has-text-weight-bold"
          >
            dpo@medgrocer.com
          </a>
          . We may decline requests that are unreasonably repetitive, require
          disproportionate technical effort, jeopardize the privacy of others,
          or are extremely impractical.
        </Collapsible>

        <Collapsible title="Contact MedGrocer’s Data Privacy Officer">
          Contact us. MedGrocer’s Data Privacy Officer provides support with any
          data privacy related questions, comments, concerns or complaints. You
          may contact MedGrocer’s Data Privacy Officer through the following
          information: MedGrocer 24F Centuria Medical Makati, Kalayaan cor.
          Salamanca St., Makati City <br />
          Contact Number: +63 917 846 1677
          <br />
          Email address:{" "}
          <a
            href="mailto:dpo@medgrocer.com"
            className="has-text-weight-bold has-text-primary"
          >
            dpo@medgrocer.com
          </a>
          MedGrocer commits to resolve complaints about our collection or use of
          your personal information. Individuals with inquiries or complaints
          regarding our Privacy Statement should first contact MedGrocer. If you
          have an unresolved privacy or data use concern that we have not
          addressed satisfactorily, please contact our DPO (refer to the contact
          details above). MedGrocer’s Data Privacy Officer will always use
          reasonable efforts to address and settle any requests or complaints
          brought to its attention. Corporate Services
        </Collapsible>
      </div>
    </Container>
  )
}

export default PrivacyPolicy
